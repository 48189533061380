import React from 'react'
import PropTypes from 'prop-types'

import {
    FacebookShareButton,
    TwitterShareButton,
    PinterestShareButton,
    EmailShareButton,
  } from 'react-share';


export default class ArtistMeta extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired
    }
    render() {
        const { country, years, media, title, slug } = this.props
        return(
            <div className="artist-single__meta columns">
                <div className="is-mobile column">
                    { country }
                </div>
                <div className="is-mobile column">
                    { years ? years : false }
                </div>
                <ul className="is-mobile column">
                    <span>Share</span>
                    <li><FacebookShareButton url={'https://qcontemporary.com/collection/' + slug} quote={title + ' | Q Contemporary'}>Facebook</FacebookShareButton></li>
                    <li><TwitterShareButton url={'https://qcontemporary.com/collection/' + slug} quote={title + ' | Q Contemporary'}>Twitter</TwitterShareButton></li>
                    <li><PinterestShareButton url={'https://qcontemporary.com/collection/' + slug} quote={title + ' | Q Contemporary'} media={media}>Pinterest</PinterestShareButton></li>
                    <li><EmailShareButton url={'https://qcontemporary.com/collection/' + slug} quote={title + ' | Q Contemporary'}>E-mail</EmailShareButton></li>
                </ul>    
            </div>
        )
    }
}

ArtistMeta.propTypes = {
    country: PropTypes.string,
    media: PropTypes.string,
    title: PropTypes.string,
    slug: PropTypes.string
}