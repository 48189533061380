import React from 'react'
import PropTypes from 'prop-types'
import Img from "gatsby-image"
import Reveal from 'react-reveal/Reveal';

export default class ContentBlocks extends React.Component {
  
  checkContentType(block) {
    const typename = block.__typename;
    if (typename === "WordPressAcf_text_block") {
      return (
        <Reveal effect="fadeInUp">
          <div className="column" dangerouslySetInnerHTML={{__html: block.text}}></div>
        </Reveal>
      )
    } else if (typename === "WordPressAcf_image_block") {
      return (
        <Reveal effect="fadeInUp">
          <div className="image">
            <Img fluid={block.image.localFile.childImageSharp.fluid} key={block.image.id}/>
            <div className="image__caption column is-9-desktop is-8-widescreen">
              {block.image.caption ? 
                <p dangerouslySetInnerHTML={{ __html: block.image.caption }} />
              : null }
              {block.image.acf ? 
                <p dangerouslySetInnerHTML={{ __html: block.image.acf.copyright }} />
              : null }
            </div>
          </div>
        </Reveal>
      )
    }
  }

  render() {
    const blocks = this.props.content;
    return (
      <div className="content-blocks columns is-multiline full-justify">
        {Object.keys(blocks).map((i) => (
          <div className={ "block column is-12 " + blocks[i].__typename } key={i}>
              { this.checkContentType(blocks[i]) }
          </div>
        ))}
      </div>
    )
  }
}

ContentBlocks.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
}
