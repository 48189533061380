import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Reveal from 'react-reveal/Reveal';
import { graphql } from 'gatsby'
import SectionHead from '../components/SectionHead'
import ArtistMeta from '../components/ArtistMeta'
import ContentBlocksSimple from '../components/ContentBlocksSimple'
import Img from 'gatsby-image'

export const ArtistTemplate = ({
  artist,
  title,
  slug,
  location
}) => {
  return (
    <section className="artist-single">
      <SectionHead title={title} />
      <div className="container content">
        {artist.featured_media.localFile ?
            <Reveal effect="fadeInUpSlow">
              <ArtistMeta country={artist.acf.country} years={artist.acf.years} location={location} title={title} slug={slug} media={artist.featured_media.localFile.childImageSharp.fluid.src} />
            </Reveal>
          : false
        }

        {artist.acf.intro ?
          <Reveal effect="fadeInUpSlow">
            <div id="intro" className="artist-single__intro columns is-multiline">
              <div className="column artist-single__text is-6-tablet is-5-desktop">
                <h2 dangerouslySetInnerHTML={{ __html: artist.acf.intro }} />
              </div>
            </div> 
          </Reveal>
        : false 
        }

        <div className="artist-single__content columns is-multiline">
          <Reveal effect="fadeInUpSlow">
            <div className="column artist-single__text is-6-tablet is-5-desktop">
              { artist.acf.content_artist
                ?  <ContentBlocksSimple content={artist.acf.content_artist} />
                : false
              }
            </div>
          </Reveal>
          <div className="artist-single__artworks column is-12-mobile is-6-tablet is-5-desktop is-offset-1-desktop">
            {artist.acf.artworks_ ? 
              Object.keys(artist.acf.artworks_).map((i) => (
              <Reveal effect="fadeInUpSlow">
                <div className="artist-single__image">

                  {artist.acf.artworks_[i].image.localFile.childImageSharp ?
                    <Img style={{ maxHeight: '80vh', width: '100%'}} fluid={artist.acf.artworks_[i].image.localFile.childImageSharp.fluid} key={artist.acf.artworks_[i].image.id}/>
                  : false }

                  <div className="artist-single__caption column is-9-desktop is-8-widescreen">
                    <i>{artist.acf.artworks_[i].info.title ? artist.acf.artworks_[i].info.title : false}</i><br/>
                    {artist.acf.artworks_[i].info.description ? 
                      <div dangerouslySetInnerHTML={{ __html: artist.acf.artworks_[i].info.description }} />
                    : false}
                    {artist.acf.artworks_[i].info.caption ?
                      <div dangerouslySetInnerHTML={{ __html: artist.acf.artworks_[i].info.caption }} />
                    : false }
                     {artist.acf.artworks_[i].image.acf ?
                      <p dangerouslySetInnerHTML={{ __html: artist.acf.artworks_[i].image.acf.copyright }} />
                    : false }
                  </div>
                </div>
              </Reveal>
            )) : false }

          </div>

        </div>
      </div>
    </section>
  )
}

ArtistTemplate.propTypes = {
  content: PropTypes.node,
  title: PropTypes.string,
  location: PropTypes.object
}

const Artist = ({ data }) => {
  const { wordpressWpArtists: post } = data

  return (
    <div>
      <Helmet 
        title={`${post.title} | Q Contemporary`} 
      >
        {post.featured_media.localFile.childImageSharp.sizes.src ? 
          <meta property="og:image" content={post.featured_media.localFile.childImageSharp.sizes.src} />
        : null }
      </Helmet>
      <ArtistTemplate
        artist={post}
        title={post.title}
        date={post.date}
        slug={post.slug}
      >
      </ArtistTemplate>
    </div>
  )
}

Artist.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Artist

export const pageQuery = graphql`
  fragment ArtistFields on wordpress__wp_artists {
    id
    slug
    content
    date(formatString: "MMMM DD, YYYY")
    title
  }
  query ArtistByID($id: String!) {
    wordpressWpArtists(id: { eq: $id }) {
      id
      title
      slug
      content
      featured_media {
        localFile {
          childImageSharp {
              # Try editing the "maxWidth" value to generate resized images.
                fluid(maxWidth: 1680) {
                  ...GatsbyImageSharpFluid
                }
                sizes {
                  src
                }
            }
        }
      }
      date(formatString: "MMMM DD, YYYY")
      acf {
        country
        years
        intro
        content_artist {
          ... on WordPressAcf_text_block {
            __typename
            text
          }
          ... on WordPressAcf_image_block {
            __typename
            image {
              id
              caption
              acf {
                copyright
              }
              localFile {
                childImageSharp {
                    # Try editing the "maxWidth" value to generate resized images.
                    fluid(maxWidth: 1680) {
                      ...GatsbyImageSharpFluid
                    }
                }
           	  }
            }
          }
        }
        artworks_ {
          info {
            title
            description
            caption
            visibility
          }
          image {
              acf {
                copyright
              }
              localFile {
                  childImageSharp {
                      # Try editing the "maxWidth" value to generate resized images.
                      fluid(maxWidth: 1680) {
                        ...GatsbyImageSharpFluid
                      }
                  }
              }
          }
        }
      }
    }
  }
`
